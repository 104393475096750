export enum EcommMetrics {
	PAYMENT_METHOD_SELECTION_PAGE = "Payment Method selection ",
	CREDIT_OR_DEBIT_CARD_PAGE = "Credit or Debit Card payment ",
	CREDIT_OR_DEBIT_CARD_BUTTON = "Credit or Debit Card Selected",
	INSTANT_EFT_BUTTON = "Instant EFT Selected",
	SOURCE = "Web3/profile",
	RETURN_TO_ORDER = "Return to order",
	GOOGLE_PAY = "Google Pay Selected",
	BACK_TO_MERCHANT_STORE = "Back to merchant store",
	SUCCESS_PAGE = "Success",
	FAILURE_PAGE = "Failure page",
	PLUGIN_FAILURE = "Plugin Failure page",
}
