import React from "react"
import IkhokhaLoader from "../../../assets/images/iK-Load-Animation.gif"
import "./index.css"
import { Box, Typography } from "@mui/material"

type LoaderProps = {
	message?: string
}

const Loader: React.FunctionComponent<LoaderProps> = ({ message = "Loading..." }) => {
	return (
		<Box>
			<Box className="loaderAnimContainer expanded">
				<img className=".loaderImage customIcon" src={IkhokhaLoader} alt="iKhokha Loader" />
				<Typography variant="subtitle2" >{message}</Typography>
			</Box>
		</Box>
	)
}

export default Loader;