import "./assets/styles/global.css"
import { inject, observer } from "mobx-react"
import React from "react"
import { Route, Routes } from "react-router-dom"
import PaymentMethodPage from "./mui/pages/PaymentMethodPage"
import { RootStore } from "./utils/stores"
import { hotjar } from 'react-hotjar';
import { appConfig } from "./utils/config"
import NotFoundPage from "./mui/pages/NotFoundPage"
import FailurePage from "./mui/pages/FailurePage"
import SuccessPage from "./mui/pages/SuccessPage";
import PluginFailurePage from "./mui/pages/PluginFailurePage"
import ChallengeResponse from "src/components/challengeResponse"
import ThreeDS from "src/mui/pages/ThreeDS"

export interface AppProps {
	rootStore?: RootStore
}

@inject("rootStore")
@observer
class App extends React.Component<AppProps> {
	rootStore: RootStore
	state = {
		eligiblePaymentResponse: [],
	}

	constructor(props: AppProps) {
		super(props)

		if (!props.rootStore) throw new Error("Root store injection failed")

		this.rootStore = props.rootStore
	}

	componentDidMount() {
		if (appConfig.stage === "prod") {
			hotjar.initialize(appConfig.hotjarId, appConfig.hotjarSV)
		}
	}

	render() {
		this.rootStore.payLinkDataStore.setReferrerOnlyIfNotOrigin()

		return (
			<Routes>
				<Route path="cres-failure" element={<ChallengeResponse rootStore={this.rootStore} isSuccessRoute={false} />} />
				<Route path="cres-redirect" element={<ChallengeResponse rootStore={this.rootStore} isSuccessRoute={true} />} />
				<Route path="failure" element={<FailurePage rootStore={this.rootStore} />} />
				<Route path="success" element={<SuccessPage rootStore={this.rootStore} />} />
				<Route path="plugin-failure" element={<PluginFailurePage />} />
				<Route path=":token/3ds" element={<ThreeDS />} />
				<Route path=":token" element={<PaymentMethodPage rootStore={this.rootStore} />} />
				<Route path="*" element={<NotFoundPage rootStore={this.rootStore} />} />
			</Routes>
		)
	}
}

export default App