
import React from "react"
import { Container as MuiContainer, Box, Typography, Paper } from "@mui/material"

export interface IContainer {
	src: string
	alt: string
	title: string
	subtitle?: string
	message?: string
	errorMessage?: string
	children?: any
}

export const Container: React.FunctionComponent<IContainer> = (props) => {
	// const theme = useTheme()
	// const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

	// Determine the size of the box based on mobile or desktop view
	//const boxSize = isMobile ? 120 : 160 // Example sizes
	return (
		<MuiContainer
			maxWidth="sm"
			sx={{
				width: {
					md: "700px",
					lg: "700px",
					sm: "450px",
					xs: "350px",
				},
				height: "556px",
			}}
		>
			<Paper
				elevation={0}
				sx={{
					mt: 4,
					p: 2,
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
					width: "100%",
					paddingBottom: "180px",
					marginBottom: 6,
				}}
			>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						overflow: "hidden",
						p: 1,
						paddingBottom: 3,
					}}
				>
					<img
						src={props.src}
						alt={props.alt}
						style={{
							width: "230px",
							height: "230px",
						}}
					/>
				</Box>
				<Typography variant="h4" component="h1" sx={{ mt: 2, textAlign: "center", fontWeight: "bold" }}>
					{props.title}
				</Typography>
				<Typography variant="subtitle1" sx={{ mt: 2, textAlign: "center" }}>
					{props.message}
				</Typography>
				<Box
					sx={{
						paddingBottom: 4,
					}}
				></Box>
				{props.children}
			</Paper>
			<Box
				sx={{
					paddingBottom: 8,
				}}
			></Box>
		</MuiContainer>
	)
}
