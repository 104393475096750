import Navbar from "../navbar/NavBar"
import "../../../assets/styles/global.css"
import React from "react"
import { Box } from "@mui/material"

export default function Layout({ children }: { children: any }) {
	return (
		<div className="layout">
			<Navbar />
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				{children}
			</Box>
		</div>
	)
}
