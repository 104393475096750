import axios, { AxiosError } from "axios"
import { appConfig, appConfigCNP } from "./config"
import { ResponseCode } from "@ikhokha/commons-ecomm/dist/types/Enum"
import { UserIdentities } from "@ikhokha/commons-ecomm/dist/types/Types"

// TODO: Refactor this. Isolating types during dev
export type BasePaymentResponse = {
	responseCode: ResponseCode
	responseMessage?: string
}




export const isThreeDsPreEnrollResponse = (obj: unknown): obj is ThreeDsPreEnrollResponse => {
	return typeof obj === "object" && obj !== null && "returnContent" in obj && "status" in obj
}

export const isThreeDsEnrollResponse = (obj: unknown): obj is EcommService.PureThreeDsEnrollResponse => {
	return (
		typeof obj === "object" &&
		obj !== null &&
		//&& "redirectToACSForm" in obj
		"status" in obj
	)
}

export const isPerformTransactionCNPResponse = (obj: unknown): obj is EcommService.PerformTransactionCNP => {
	return typeof obj === "object" && obj !== null && "responseStatus" in obj
}

export namespace EcommService {
	export type CNPPaymentErrorResponse = {
		errorCode: string
		errorMessage: string
		displayedErrorHeader: string
		displayedErrorMessage: string
		displayTryAgain: boolean
	}

	export type PureThreeDsEnrollResponse = {
		// FIXME: Confirm we alway get this as types are optional
		redirectToACSForm: string
		status: string
	} & BasePaymentResponse

	export type ThreeDsEnrollRequest = {
		token: string
		isTestTransaction?: boolean
	}

	export type PerformTransactionRequest = {
		localTransmissionDateTimeInUnixEpocSec: number
		token: string
	}
    export const threeDSResCodes = {
        SUCCESS: "SUCCESS",
        FAILURE: "FAILURE",
        PENDING_ENROLLMENT: "PENDING_ENROLLMENT",
        PENDING_ACS: "PENDING_ACS",
    }
	/**
	 * FIXME: Copied these types from backend
	 */
	export function isThreeDsEnrollResponse(x: unknown): x is PureThreeDsEnrollResponse {
		return (
			(x as BasePaymentResponse).responseCode === ResponseCode.CNP_00 && (x as PureThreeDsEnrollResponse).status !== undefined
		)
	}

	export type PerformTransactionCNP = {
		responseCode: string
		responseStatus: string
		errorResponse?: CNPPaymentErrorResponse
	}

	type PurePerformTransactionResponse = {
		performTransactionCNP?: PerformTransactionCNP
	}

	export type PerformTransactionResponse = PurePerformTransactionResponse & BasePaymentResponse

	export function isPerformTransactionResponse(x: unknown): x is PerformTransactionResponse {
		return (
			(x as PurePerformTransactionResponse).performTransactionCNP?.responseStatus !== undefined &&
			// FIXME: Fix the typing errors
			//@ts-ignore
			(x as BasePaymentResponse).responseCode === ResponseCode.CNP_00
		)
	}

	export type ExternalRefRequest = {
		externalReference: string
	}
	export type ThreeDsPreEnrollRequest = {
		pan: string
		expiryDate: string
		cvv: string
		cardholderName: string
		purchaseAmount: string

		token: string
	}

	export type PurePreEnrollRequest = {
		/**
		 * FIXME: Should add a enum in future
		 */
		status: string
		tdsMethodContent: string
	} & BasePaymentResponse

	/**
	 * FIXME: Just making assumptions here
	 */
	export function isThreePreDsPreEnrollResponse(x: unknown): x is PurePreEnrollRequest {
		return (x as BasePaymentResponse).responseCode === ResponseCode.CNP_00 && (x as PurePreEnrollRequest).status !== undefined
	}
}

/**
 * Limited data from the 'Get Payment Link Details' API
 */
type PayLinkDataRawResponse = {
	id: string
	client: {
		platformName: string
		platformVersion: string
	}
	owner: string
	amount: number
	applicationId: string
	successUrl: string
	failUrl: string
	callbackUrl: string
	status: string
	customerPhone?: string
	customerEmail?: string
	customerName?: string
	/**
	 * This is currently unused and does not need a type
	 */
	createdAt: string
	modifiedAt: string
	test: boolean
	cancelUrl?: string
	type: string
	tradingName?: string
	reference?: string
	customReference?: string
}

type PayLinkRawData = {
	client: {
		platformName: string
		platformVersion: string
	}
	amount: number
	status: string
	customerName?: string
	successUrl: string
	failUrl: string
	test: boolean
	cancelUrl?: string
	owner: string
	type: string
	tradingName?: string
	reference?: string
	customReference?: string
	createdAt: string
}
type TransactionRawResponse = {
	responseCode: string
	responseMessage?: string
	amount: number
	successUrl?: string
	failureUrl?: string
	cancelUrl?: string
	tryAgainUrl?: string
	displayConfig?: {
		backToOrder: boolean
		backToMerchantStore: boolean
	}
	trackEvents?: boolean
	eventsUserIdentities?: UserIdentities
	maskedPan?: string
	cardExpiry?: string
}

type TransactionData = {
	amount: number
	successUrl: string
	failureUrl: string
	cancelUrl: string
	tryAgainUrl: string
	displayConfig?: {
		backToOrder: boolean
		backToMerchantStore: boolean
	}
	trackEvents?: boolean
	eventsUserIdentities?: UserIdentities
	maskedPan?: string
	cardExpiry?: string
}

type HttpErrorResponse = { success: false; statusCode: number; message?: string; cnpErrorDefined?: CNPErrorDefined }
type CNPErrorDefined = { header: string; message: string; tryAgain: boolean }
type HttpSuccessResponse<ResponseData> = { success: true; data: ResponseData }

const generateHttpError = (e: unknown): HttpErrorResponse => {
	// Check for an axios code that isn't our expected success code
	const status = (e as AxiosError).response?.status
	const errorMessage = (e as AxiosError).response?.data?.error

	if (status !== undefined) {
		if (typeof errorMessage === "string") {
			console.log("1. " + errorMessage)
			return {
				success: false,
				statusCode: status,
				message: errorMessage,
			}
		}
		console.log("2. " + errorMessage)
		return {
			success: false,
			statusCode: status,
		}
	} else {
		console.log("3. " + errorMessage)
		return {
			success: false,
			statusCode: 500,
			message: "Please try again or contact support.",
		}
	}
}

type HttpCall<Args, ResponseData> = (args: Args) => Promise<HttpSuccessResponse<ResponseData> | HttpErrorResponse>

export const getPaymentLinkDetails: HttpCall<{ token: string }, PayLinkRawData> = async ({ token }) => {
	try {
		// FIXME: Add run time checking of the returned data
		const {
			data: {
				amount,
				client,
				status,
				customerName,
				failUrl,
				successUrl,
				test,
				cancelUrl,
				owner,
				type,
				tradingName,
				customReference,
				createdAt,
			},
		} = await axios.get<PayLinkDataRawResponse>(`https://securepay.ikhokha-escape.blue/${token}/details`, {
			timeout: appConfig.timeout,
		})

		return {
			success: true,
			data: {
				amount: amount,
				client: client,
				status: status,
				customerName: customerName,
				failUrl: failUrl,
				successUrl: successUrl,
				test: test,
				cancelUrl: cancelUrl,
				owner,
				type,
				tradingName: tradingName,
				customReference: customReference,
				createdAt,
			},
		}
	} catch (e) {
		console.log(e)
		return generateHttpError(e)
	}
}

export const getTransactionDetails: HttpCall<{ token: string }, TransactionData> = async ({ token }) => {
	try {
		// FIXME: put error handler here for response code and response message
		const {
			data: {
				amount,
				failureUrl,
				successUrl,
				cancelUrl,
				displayConfig,
				trackEvents,
				tryAgainUrl,
				eventsUserIdentities,
				maskedPan,
				cardExpiry,
			},
		} = await axios.get<TransactionRawResponse>(`https://${appConfigCNP.paymentCNPServiceUrl}/v1/${token}/transaction`, {
			timeout: appConfig.timeout,
		})
		return {
			success: true,
			data: {
				amount,
				failureUrl: failureUrl!!,
				successUrl: successUrl!!,
				cancelUrl: cancelUrl!!,
				tryAgainUrl: tryAgainUrl!!,
				displayConfig: displayConfig,
				trackEvents,
				eventsUserIdentities,
				maskedPan,
				cardExpiry,
			},
		}
	} catch (e) {
		console.log(e)
		return generateHttpError(e)
	}
}

export const threeDsEnroll = async (
	request: EcommService.ThreeDsEnrollRequest,
): Promise<BasePaymentResponse | EcommService.PureThreeDsEnrollResponse> => {
	try {
		const { data } = await axios.post<EcommService.PureThreeDsEnrollResponse | BasePaymentResponse>(
			`https://${appConfigCNP.paymentCNPServiceUrl}/v1/enroll`,
			request,
			{ timeout: appConfigCNP.timeout },
		)

		if (EcommService.isThreeDsEnrollResponse(data)) {
			return data
		}

		return data
	} catch (e) {
		console.log(e)

		return {
			responseCode: ResponseCode.CNP_05,
			responseMessage: "Error during Enroll.",
		}
	}
}

type ThreeDsPreEnrollRequest = {
	pan: string
	/**
	 * YYMM
	 * @example 2201
	 */
	expiryDate: string
	cvv: string
	cardholderName: string
	purchaseAmount: string
	token: string
}

type ThreeDsPreEnrollResponse = {
	status: string
	returnContent: string
} & BasePaymentResponse & { transactionId: string }

export enum PaymentLinkStatus {
	UNPAID = "UNPAID",
	PAID = "PAID",
	EXPIRED = "EXPIRED",
	REFUNDED = "REFUNDED",
	PARTIALLY_REFUNDED = "PARTIALLY_REFUNDED",
	CANCELED = "CANCELED",
	FAILED = "FAILED",
}

export const threeDsPreEnroll = async (req: ThreeDsPreEnrollRequest): Promise<ThreeDsPreEnrollResponse | BasePaymentResponse> => {
	try {
		const request: EcommService.ThreeDsPreEnrollRequest = req

		const { data } = await axios.post<EcommService.PurePreEnrollRequest | BasePaymentResponse>(
			`https://${appConfigCNP.paymentCNPServiceUrl}/v1/pre-enroll`,
			request,
			{ timeout: appConfigCNP.timeout },
		)

		if (EcommService.isThreePreDsPreEnrollResponse(data)) {
			return {
				responseCode: data.responseCode,
				responseMessage: data.responseMessage,
				status: data.status,
				//@ts-ignore
				returnContent: data.tdsMethodContent || data.redirectToACSForm,
			}
		}

		return data
	} catch (e) {
		console.log(e)

		return {
			responseCode: ResponseCode.CNP_04,
			responseMessage: "Error during tdsPreEnroll.",
		}
	}
}

/*  preform-transaction call in cres-redirect */
export type PerformTransactionResponse = {
	performTransactionCNP?: EcommService.PerformTransactionCNP
} & BasePaymentResponse

// function isCNPPaymentErrorResponse(
// 	data: EcommService.PerformTransactionErrorResponse | EcommService.PerformTransactionResponse,
// ): data is EcommService.PerformTransactionErrorResponse {
// 	return (
// 		(data as EcommService.CNPPaymentErrorResponse).errorResponse !== undefined &&
// 		(data as EcommService.CNPPaymentErrorResponse).errorResponse.errorCode !== undefined &&
// 		(data as EcommService.CNPPaymentErrorResponse).errorResponse.errorMessage !== undefined &&
// 		(data as EcommService.CNPPaymentErrorResponse).errorResponse.displayTryAgain !== undefined
// 	)
// }

export const performTransaction = async (token: string): Promise<EcommService.PerformTransactionCNP | BasePaymentResponse> => {
	try {
		const request: EcommService.PerformTransactionRequest = {
			localTransmissionDateTimeInUnixEpocSec: Math.round(Date.now() / 1000),
			token: token,
		}

		/*json.strigify */
		const { data } = await axios.post<EcommService.PerformTransactionResponse>(
			`https://${appConfigCNP.paymentCNPServiceUrl}/v1/perform-transaction`,
			request,
			{ timeout: appConfigCNP.timeout },
		)

		console.log(data)

		if (data.responseCode === ResponseCode.CNP_00 && data.performTransactionCNP) {
			return data.performTransactionCNP
		} else {
			return data as BasePaymentResponse
		}
	} catch (e) {
		console.log(e)

		return {
			responseCode: ResponseCode.CNP_14,
			responseMessage: "Error processing transaction",
		}
	}
}

export const cancelTransaction = async (token: string): Promise<void> => {
	try {
		const request = {
			status: "CANCELLED",
		}

		await axios.post<void>(`https://${appConfigCNP.paymentCNPServiceUrl}/v1/${token}/cancel`, request, {
			timeout: appConfigCNP.timeout,
		})
	} catch (e) {
		console.log(e)
	}
}
