import React from "react"
import ErrorComponent from "../../../components/error/error"
import { RootStore } from '../../../utils/stores'

type NotFoundPageProps = {
	rootStore: RootStore
}

const NotFoundPage: React.FunctionComponent<NotFoundPageProps> = ({ rootStore: { payLinkDataStore } }) => {
	const payRef = payLinkDataStore.payRef

	return (
		<ErrorComponent errorMessage="" titleMessage={"404: Page not found"} tokenForRetry={payRef}></ErrorComponent>
	)
}

export default NotFoundPage
