import React from "react"
import eventsTracker from "../../../helpers/eventsTracker"
import { IButtonClickEventAttributes, mxPanelEventName } from "../../../utils/mxPanel"
import {Typography } from "@mui/material"

const linkStyle: React.CSSProperties = {
	fontSize: "16px",
	fontWeight: "600",
	color: "#09828b",
	letterSpacing: "1px",
	cursor: "pointer",
	background: "none",
	border: "none",
	appearance: "none",
	margin: "30px auto",
}

type ReturnToOrderProps = {
	returnUrl: string
	fromPage: string
	platformName: string | undefined
	eventTracker: eventsTracker
	pageReferrer? : string
}
const ReturnToOrder: React.FC<ReturnToOrderProps> = ({ returnUrl, fromPage, platformName, eventTracker, pageReferrer }) => {
	const onOpenReturnUrl = (event: any) => {
		event.preventDefault()
		const mxEvent: IButtonClickEventAttributes = {
			page_title: fromPage,
			page_url: `${window.location.href}`,
			button_text: "Return To Order",
			button_url: returnUrl,
			date_time: new Date().toDateString(),
			module_title: "Return To Order",
			platformName: platformName,
			page_referrer : pageReferrer
		}
		eventTracker.logCustomEvent(mxPanelEventName.BUTTON_CLICK, mxEvent)
		window.open(returnUrl, "_self")
	}
	return (
		<Typography sx={linkStyle} onClick={onOpenReturnUrl}>
			Return to order
		</Typography>
	)
}

export default ReturnToOrder
