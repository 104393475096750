import { Box, CircularProgress, Stack, Typography } from "@mui/material"
import "./panel.css"
import ArrowRight from "../../../assets/images/arrowRight.svg"
import React from "react"
import { theme } from "@ikhokha/commons-ui"

export interface IRadio {
	className: string
	src: any
	alt: string
	title: string
	message: string
	ref?: any
	onClick?: (e: React.MouseEventHandler<HTMLButtonElement>) => void
	disabled?: boolean
	isloading?: boolean
	servicename?: string
	isProgressLoadingIFT?: boolean
}

const Panel: React.FunctionComponent<IRadio> = (props: any) => {
	const { disabled, isloading, servicename, isProgressLoadingIFT } = props

	const renderServiceComponent = () => {
		if (servicename === "payment-cnp") {
			if (isloading) {
				return <CircularProgress sx={{ color: "#898b8c" }} size={24} />
			} else {
				return <img src={ArrowRight} alt="Arrow logo" />
			}
		} else if (servicename === "payment-ift") {
			if (isProgressLoadingIFT) {
				return <CircularProgress sx={{ color: "#898b8c" }} size={24} />
			} else {
				return <img src={ArrowRight} alt="Arrow logo" />
			}
		} else {
			return null
		}
	}

	return (
		<Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
			<Stack
				sx={{ alignItems: "center", borderRadius: "8px" }}
				direction={"row"}
				{...props}
				className={props.className + `${disabled ? "disabled" : ""}`}
				ref={props.ref}
				onClick={disabled ? () => {} : props.onClick}
			>
				<Box className="RadioBoxOption_detail_image" sx={{ marginTop: "0px !important" }}>
					<img src={props.src} alt={props.alt} />
				</Box>
				<Box className={`RadioBoxOption_detail_container ${disabled ? "disabled" : ""}`}>
					<Typography variant="body1" sx={{ color: theme.palette.text.primary }}>
						{props.title}
					</Typography>
					<Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
						{props.message}
					</Typography>
				</Box>
				{renderServiceComponent()}
			</Stack>
		</Box>
	)
}

export default Panel
