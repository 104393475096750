import { Box, Divider, Link, Stack, Typography } from "@mui/material"
import { theme } from "@ikhokha/commons-ui/build/dist/cjs"
import emailLogo from "../../../assets/images/email-envelop.svg"
import phoneLogo from "../../../assets/images/beats-logo.svg"
import ArrowDown from "../../../assets/images/CaretDown.svg"

type DisplayInformationPanel = {
	displayContactDetails: any
	displayDetails: Function
	isMobileView: Boolean
}

export const InformationPanel: React.FC<DisplayInformationPanel> = ({ displayContactDetails, displayDetails , isMobileView }) => {
	return (
		<Stack
			sx={{
				borderRadius: "16px",
				backgroundColor: theme.palette.grey[100],
				minHeight: "72px",
				justifyContent: "center",
				alignItems: "center",
				padding: "20px",
			}}
		>
			<Stack
				direction={"row"}
				justifyContent={"space-between"}
				sx={{
					width: "100%",
					paddingBottom: displayContactDetails ? "20px" : "",
					'&:hover': {
						cursor: 'pointer',
					},
				}}
				onClick={() => {
					displayDetails()
				}}
			>
				<Typography
					variant="subtitle1"
					sx={{
						color: theme.palette.text.primary,
					}}
				>
					Need help with your payment?
				</Typography>
				<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
					<img
						src={ArrowDown}
						alt="Arrow logo"
					/>
				</Box>
			</Stack>
			{displayContactDetails && (
				<>
					<Divider
						sx={{
							width: "90%",
							height: "1px",
						}}
					></Divider>
					<Stack
						direction={"row"}
						alignItems={"center"}
						sx={{
							width: "100%",
							paddingTop: "20px",
							paddingBottom: "10px",
						}}
					>
						<img style={{ width: "24px", height: "24px", marginRight: "16px" }} src={emailLogo} alt="Email logo" />
						<Stack direction={"row"}>
							<Typography
								variant="subtitle1"
								sx={{
									color: theme.palette.text.primary,
									marginRight: "4px",
								}}
							>
								Email:
							</Typography>
							<Link
								variant="body1"
								sx={{
									color: "#09828B",
									textDecoration: "underline",
								}}
								href="mailto:support@ikhokha.com"
							>
								support@ikhokha.com
							</Link>
						</Stack>
					</Stack>
					<Stack
						direction={"row"}
						sx={{
							width: "100%",
							paddingBottom: "10px",
						}}
					>
						<img style={{ width: "24px", height: "24px", marginRight: "16px" }} src={phoneLogo} alt="Phone logo" />
						<Typography
							variant="subtitle1"
							sx={{
								color: theme.palette.text.primary,
								marginRight: "4px",
							}}
						>
							Phone:
						</Typography>
						<Link
							variant="body1"
							sx={{
								color: "#09828B",
								textDecoration: "underline",
							}}
							href="tel:0872227000"
						>
							087 222 7000
						</Link>
					</Stack>
					<Stack
						direction={"row"}
						sx={{
							width: "100%",
						}}
					>
						<Typography sx={{ marginRight: "2px" }}>Support hours: </Typography>
						<Typography sx={{ marginRight: "2px", fontWeight: "500", color: theme.palette.text.secondary }}>
							Mon to Sun
						</Typography>
						<Typography sx={{ marginRight: "2px" }}>from</Typography>
						<Typography sx={{ fontWeight: "500", color: theme.palette.text.secondary }}>07:00 - 20:00</Typography>
					</Stack>
				</>
			)}
		</Stack>
	)
}