import React, { useEffect, useState } from "react"
import errorModule from "./error.module.css"
import ErrorIcon from "../../assets/images/error-new.svg"
import cards from "../../config/cards"
import { PlatformName } from "../../utils"
import { getPaymentLinkDetails, PaymentLinkStatus, wixCancelOrder } from "../../utils/requests"
import { PageRoutes } from "../../utils/routes"
import { Card } from "../cards/Card"
import Footer from "../footer/Footer"
import Layout from "../layout/Layout"
import Logger from "../../utils/logger"
import { useNavigate } from "react-router-dom"
import { Box, Button, Typography } from "@mui/material"

interface IError {
	/**
	 * The error sub heading
	 */
	errorMessage?: string
	/**
	 * The main error heading
	 */
	titleMessage: string
	/**
	 * Give the paylink token for retry purposes
	 */
	tokenForRetry?: string
}

const errorTitleStyle: React.CSSProperties = {
	color: "#E54360",
}

const ErrorComponent: React.FunctionComponent<IError> = (props) => {
	const navigate = useNavigate()
	const token = props.tokenForRetry
	Logger.rumError(`An Error Occurred: Message = ${props.titleMessage} ${props.errorMessage}. Token = ${token}`)
	const [enableTryAgain, setEnableTryAgain] = useState(false)

	useEffect(() => {
		;(async () => {
			if (token) {
				const paymentLink = await getPaymentLinkDetails({ token })
				console.log("useEffect: paymentLink", paymentLink)

				if (paymentLink.success) {
					const { client, failUrl } = paymentLink.data
					updatePaymentRequest(token, client.platformName, failUrl)
				}
			}
		})()
	})

	const updatePaymentRequest = async (token: string, platformName: string, failUrl: string) => {
		if (platformName === PlatformName.WIX) {
			setEnableTryAgain(false)
			await wixCancelOrder(token, PaymentLinkStatus.FAILED)
			console.log("useEffect updatePaymentRequest: isWixPlugin", enableTryAgain)

			window.setTimeout(() => {
				window.location.replace(failUrl)
			}, 1000)
		} else {
			setEnableTryAgain(true)
		}
	}

	return (
		<>
			<Layout>
				<main className={errorModule.main_container}>
					<section className={errorModule.main_section}>
						<div className={errorModule.notFound_container}>
							<Box
								sx={{
									paddingBottom: {
										md: 6,
										lg: 6,
										sm: 0,
										xs: 0,
									},
								}}
							>
								<img src={ErrorIcon} alt="Error Page" />
							</Box>

							<Typography variant="h1">{props.titleMessage}</Typography>
							{props.errorMessage && <h3 style={errorTitleStyle}>{props.errorMessage}</h3>}

							<Typography className={errorModule.message}>
								We were unable to process your payment. Please try again or contact our support team for further help.
							</Typography>

							<Box
								sx={{
									paddingTop: {
										md: 6,
										lg: 6,
										sm: 4,
										xs: 2,
									},
								}}
							>
								{token && (
									<Button
										sx={{
											width: {
												md: "200px !important",
												lg: "400px !important",
												sm: "100% !important",
												xs: "100%",
											},
											height: "48px",
											fontWeight: "bold",
											fontSize: "20px",
											borderRadius: "16px",
											textAlign: "center",
										}}
										onClick={() => {
											navigate(PageRoutes["HomePage"](token))
										}}
									>
										Go to Home
									</Button>
								)}
							</Box>
						</div>
					</section>
				</main>
				<Footer />
				<div className="support_header">
					<h2>Got questions or need our help?</h2>
				</div>
				<div className="card_container">
					{cards.map((card, index) => {
						return (
							<Card
								key={index}
								src={card.src}
								alt={card.alt}
								heading={card.heading}
								href={card.href}
								message={card.message}
								altMessage={card.altMessage}
							/>
						)
					})}
				</div>
			</Layout>
		</>
	)
}
export default ErrorComponent
