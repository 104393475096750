import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter } from "react-router-dom"
import App from "./App"
import { Provider } from "mobx-react"
import reportWebVitals from "./reportWebVitals"
import { RootStore } from "./utils/stores"
import Logger from "./utils/logger"
import { ThemeProvider } from "@mui/material"
import { theme } from "@ikhokha/commons-ui/build/dist/cjs/theme"
import { initDataDog } from "src/helpers/datadog"

Logger.startSession()

const rootStore = new RootStore()

initDataDog();

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<Provider rootStore={rootStore}>
				<ThemeProvider theme={theme}>
					<App />
				</ThemeProvider>
			</Provider>
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById("root"),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
