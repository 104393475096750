import React, { useState } from "react"
import "./button.css"
import {  Button } from "@mui/material"

export function buttonClickHandler(setClicked: (clicked: boolean) => void, propsOnClicked: () => void) {
	setClicked(true)

	if (propsOnClicked !== undefined) {
		propsOnClicked()
	}
}

export function PrimaryButton(
	props: any & {
		href: string | undefined
		children?: JSX.Element | JSX.Element[] | string
	},
) {
	const [clicked, setClicked] = useState(false)

	return (
		<>
			<br />
			<Button
				sx={{
					width: {
						md: "50% !important",
						lg: "60% !important",
						sm: "80% !important",
						xs: "100%",
					},
					height: "48px",
					fontWeight: "bold",
					fontSize: "20px",
					borderRadius: "16px",
					textAlign: "center",
				}}
				onClick={(_) => {
					console.log(clicked)
					buttonClickHandler(setClicked, props.onClick)
				}}
			>
				<p>{props.children}</p>
			</Button>
		</>
	)
}
