import { Container } from "../../../mui/components/container/index"
import { PrimaryButton } from "../../../components/buttons/PrimaryButton"
import { RootStore } from "../../../utils/stores"
import { useEffect, useState } from "react"
import { PlatformName } from "../../../utils"
import SuccessIcon from "../../../assets/images/success_new.svg"

import React from "react"
import { IButtonClickEventAttributes, mxPanelEventName } from "../../../utils/mxPanel"
import Layout from "../../components/layout/Layout"
import { EcommMetrics } from "src/utils/metrics"
export type SuccessPageProps = {
	rootStore: RootStore
}

const SuccessPage: React.FunctionComponent<SuccessPageProps> = ({ rootStore: { payLinkDataStore, eventsTracker } }) => {
	const [merchantUrl, setMerchantUrl] = useState("")

	useEffect(() => {
		;(async () => {
			const token = localStorage.getItem("ecommPaymentRef")
			if (token) {
				const paylinkData = await payLinkDataStore.getOrFetchPayLinkData(token)
				if (paylinkData.success) {
					eventsTracker.logCustomEvent(mxPanelEventName.OTHER, {
						page_url: `${window.location.href}`,
						page_title: "Transaction Success",
					})
					if (paylinkData.data.platformName === PlatformName.WOO_COMMERCE) {
						setMerchantUrl(paylinkData.data.successUrl)
					}
				}
			}
		})()
	})

	function redirectToUrl(merchantUrl: string) {
		const event: IButtonClickEventAttributes = {
			page_title: EcommMetrics.SUCCESS_PAGE,
			page_url: `${window.location.href}`,
			button_text: EcommMetrics.BACK_TO_MERCHANT_STORE,
			date_time: new Date().toDateString(),
			event_type: mxPanelEventName.BUTTON_CLICK,
			button_url: merchantUrl,
		}
		eventsTracker.logCustomEvent(mxPanelEventName.BUTTON_CLICK, event)

		if (merchantUrl) {
			window.location.href = merchantUrl
		}
	}
	// merchantUrl.length === 0 this means we are dealing with a send paylink
	const messageInput =
		merchantUrl.length === 0 ? "We will notify the merchant of your payment.\n You can close this tab now." : ""
	return (
		<div>
			<Layout>
				<Container
					src={SuccessIcon}
					alt="Transaction Successful Icon"
					title="Transaction successful"
					subtitle=""
					message={messageInput}
				>
					{merchantUrl.length > 0 && (
						<PrimaryButton
							style={{ fontSize: "18px", margin: "62px 0px 0px 0px", width: "340px" }}
							onClick={() => merchantUrl && redirectToUrl(merchantUrl)}
						>
							Back to merchant store
						</PrimaryButton>
					)}
				</Container>
			</Layout>
		</div>
	)
}

export default SuccessPage
