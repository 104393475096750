type StorageInfo = { [key :string] : string };

const checkIfStorageAvailable = (): boolean => {
    if (typeof window !== "undefined" && typeof Storage !== "undefined") {
        return true;
    } else {
        console.error("Unable to access localStorage. Client does not support web storage");
        return false;
    }
}

const getLocalStorageData = (storageName: StorageTypes): StorageInfo | null => {
    if (!checkIfStorageAvailable()) return null;

    const stringOrNull = localStorage.getItem(storageName);

    return stringOrNull ? JSON.parse(stringOrNull) : null;
}

export const setLocalStorageData = (storageName: StorageTypes, 
    property: StorageKeys, 
    value: string): boolean => {
    
    if (!checkIfStorageAvailable()) return false;

    const existingData = getLocalStorageData(storageName);

    if (!existingData) {
        const newData: StorageInfo = { [property] : value};
        localStorage.setItem(storageName, JSON.stringify(newData));
    } else {
        existingData[property] = value;
        localStorage.setItem(storageName, JSON.stringify(existingData));
    }

    return true;
}

const getFromLocalStorage = (storageName: StorageTypes, property: StorageKeys): string | null => {
    const existingData = getLocalStorageData(storageName);

    if (!existingData) return null;

    return existingData[property];
}

export const enum StorageKeys {
    IKTries = "ikTries",
    PaymentRef = "paymentRef",
    ErrorSubReason = "errSubres",
    ErrorReason = "errRes",
    TryAgain = "tryAgain",
    TdsContent = "tdsContent",
	EcommPayRef = "ecommPayRef",
}

export const enum StorageTypes {
    IKPayment = "ikPayment"
}




export const setLocalStorageDataCNP = (storageName: StorageTypes, property: StorageKeys, value: string): boolean => {
	if (!checkIfStorageAvailable()) return false

	const existingData = getLocalStorageData(storageName)

	if (!existingData) {
		const newData: StorageInfo = { [property]: value }
		localStorage.setItem(storageName, JSON.stringify(newData))
	} else {
		existingData[property] = value
		localStorage.setItem(storageName, JSON.stringify(existingData))
	}

	return true
}

export default getFromLocalStorage;
