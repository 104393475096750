import CallIcon from "../assets/images/call_icon.svg";
import EmailIcon from "../assets/images/email_icon.svg";
import TimeIcon from "../assets/images/time_icon.svg";
import ICard from "../components/cards/Card";

const cards: ICard[] = [
  {
    src: EmailIcon,
    alt: "Email Icon",
    href: "mailto:support@ikhokha.com",
    heading: "Email us at",
    message: "support@ikhokha.com",
  },
  {
    src: CallIcon,
    alt: "Call Icon",
    href: "tel:+27872227000",
    heading: "Call us on",
    message: "087 222 7000",
  },

  {
    src: TimeIcon,
    alt: "Time Icon",
    heading: "Support Hours",
    altMessage: "Mon to Sun from 07:00 to 20:00",
  },
];

export default cards;
