import { Buffer } from "buffer" // Warning: Do not change this. Due to tight types coupling between react and node, Buffer will exist within the context of Node, but will not exist at runtime. This package is required
import { observer } from "mobx-react"
import React, { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
// import ReturnToOrder from "../../../components/buttons/returnToOrder"
import Loader from "../../../components/loader"
import {
	// FeatureFlags,
	// generateErrorMessage,
	generateExpiredErrorMessage,
	// PlatformName,
	threeDSResCodes,
} from "../../../utils"
import { isThreeDsEnrollResponse, isThreeDsPreEnrollResponse, threeDsEnroll, threeDsPreEnroll } from "../../../utils/3ds"
import { setLocalStorageData, StorageKeys, StorageTypes } from "../../../utils/storage"
import { RootStore } from "../../../utils/stores"
import MasterCardLogo from "../../../assets/images/master-card-logo-v3.svg"
import VisaLogo from "../../../assets/images/visa-logo-v3.svg"
import Logger from "../../../utils/logger"
import { PageRoutes } from "../../../utils/routes"
// import Footer from "../../components/footer/Footer"
import { ResponseCode } from "@ikhokha/commons-ecomm/dist/types/Enum"
import { formatCurrency, replaceLastSegmentWithHash } from "../../../utils/utils"
import { IButtonClickEventAttributes, IPageViewEventAttributes, mxPanelEventName } from "../../../utils/mxPanel"
import { Box, Button, CircularProgress, Container, Stack, TextField } from "@mui/material"
import { theme } from "@ikhokha/commons-ui"
// import { appConfig } from "../../../utils/config"
import { TransactionData } from "src/utils/requests"

type CardPaymentPageProps = {
	rootStore: RootStore
}

const LoaderThatRedirects: React.FunctionComponent<{ success: boolean; message: string }> = ({
	success,
	message = "Redirecting...",
}) => {
	//const navigate = useNavigate()
	useEffect(() => {
		setTimeout(() => {
			const redirectPage = success ? PageRoutes["Success"] : PageRoutes["Failure"]
			window.location.replace(`https://${window.location.host}${redirectPage()}`)
		}, 500)
	})
	return <Loader message={message} />
}

const CardPaymentPage: React.FunctionComponent<CardPaymentPageProps> = observer(
	({ rootStore: { payLinkDataStore, cardDataStore, eventsTracker } }) => {
		const navigate = useNavigate()
		//const { token } =  useParams()
		const token = localStorage.getItem("paymentRefToken")
		const url = localStorage.getItem("paymentUrl")
		const pageTitle = "Make a Credit or Debit Card Payment"
		if (!token) throw new Error("Path param :token is not defined")

		const [error, setErrorMessageState] = useState<{ useFriendly: boolean; message: string; canRetry?: boolean } | undefined>()
		const [isLoading, setLoading] = useState<boolean>(true)
		const [isProgress, setProgress] = useState<boolean>(false)
		const [canSubmitForm, setCanSubmitForm] = useState<boolean>(false)

		const [transactionData, setTransactionData] = useState<TransactionData | undefined>()
		const yearField = useRef<HTMLInputElement | null>(null)

		const setErrorMessage = (input: { useFriendly: boolean; message: string; canRetry?: boolean; subMessage?: string }) => {
			setErrorMessageState(input)
			setLocalStorageData(StorageTypes.IKPayment, StorageKeys.ErrorReason, input.useFriendly ? input.message : "")
			if (input.subMessage) {
				setLocalStorageData(StorageTypes.IKPayment, StorageKeys.ErrorSubReason, input.useFriendly ? input.subMessage : "")
			}
		}

		useEffect(() => {
			;(async () => {
				sessionStorage.removeItem("ThreeDS")
				setLocalStorageData(StorageTypes.IKPayment, StorageKeys.ErrorSubReason, "")
				setLocalStorageData(StorageTypes.IKPayment, StorageKeys.ErrorReason, "")
				setLocalStorageData(StorageTypes.IKPayment, StorageKeys.TryAgain, "")

				setLoading(true)

				const receivedData = await payLinkDataStore.fetchTransactionData(token)
				console.log("fetchTransactionData: ", receivedData)

				if (receivedData.success) {
					const clientId = receivedData.data.eventsUserIdentities?.clientId
					if (clientId) {
						eventsTracker.identifyUser(clientId)
					}
					const events: IPageViewEventAttributes = {
						page_title: pageTitle,
						page_url: `${window.location.href}`,
						page_referrer: window?.localStorage?.getItem("referrerSite") ?? "",
					}
					eventsTracker.logCustomEvent(mxPanelEventName.PAGE_VIEW, events)

					payLinkDataStore.setPayRef(token)
					setTransactionData(receivedData.data)
					if (receivedData.data.maskedPan && receivedData.data.cardExpiry) {
						cardDataStore.data.$.pan.onChange(receivedData.data.maskedPan)
						cardDataStore.data.$.expiryYear.onChange(receivedData.data.cardExpiry.slice(0, 2))
						cardDataStore.data.$.expiryMonth.onChange(receivedData.data.cardExpiry.slice(2, 4))
					}
					// Track page view
				} else {
					Logger.error(
						`Error occurred while fetching transaction data: Pay Ref = ${token}. Error = ${JSON.stringify(receivedData.error)}`,
					)
					// FIXME: Remove status code
					setErrorMessage({
						useFriendly: true,
						message: "This is not working", //receivedData.error.message,
					})
					setLoading(false)
					return
				}
				setLoading(false)
			})()
			// FIXME: Fix this in future
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [])

		const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
			setProgress(true)
			e.preventDefault()
			sessionStorage.removeItem("ThreeDS")

			// Track button click
			const events: IButtonClickEventAttributes = {
				page_title: "Make a Credit or Debit Card Payment",
				page_url: `${replaceLastSegmentWithHash(url as string)}`,
				button_text: "Pay",
				button_url: `${replaceLastSegmentWithHash(url as string)}`,
				date_time: new Date().toDateString(),
			}
			eventsTracker.logCustomEvent(mxPanelEventName.BUTTON_CLICK, events)

			// Calling validate will trigger validation on each input
			const formValid = await cardDataStore.data.validate()
			if (formValid.hasError) {
				// Return early and allow the display to show validation errors
				return
			}

			setLoading(true)

			const cvv = cardDataStore.data.$.cvv.value
			// reset the CVV value so it doesn't get prefilled
			cardDataStore.data.$.cvv.reset()

			const preEnrollResponse = await threeDsPreEnroll({
				cardholderName: cardDataStore.data.$.cardholderName.value,
				cvv,
				expiryDate: cardDataStore.data.$.expiryYear.value + cardDataStore.data.$.expiryMonth.value,
				pan: cardDataStore.data.$.pan.value,
				token: token,
				purchaseAmount: transactionData!.amount.toString(),
			})
			// TODO: Refactor this in the future once fully understood
			if (preEnrollResponse.responseCode === ResponseCode.CNP_00 && isThreeDsPreEnrollResponse(preEnrollResponse)) {
				const { returnContent, status } = preEnrollResponse
				console.log("preEnrollResponse: ", preEnrollResponse)

				switch (status) {
					case threeDSResCodes.PENDING_ENROLLMENT:
						Logger.info(JSON.stringify({ returnContent, status }))
						Logger.info(`opening tdsMethodContent for pre enroll: Pay Ref = ${token}`)

						// initializeHtml(returnContent)
						Logger.info(`calling enroll now: Pay Ref = ${token}`)

						const enrollResponse = await threeDsEnroll({
							token: token,
						})
						console.log("threeDsEnroll: ", enrollResponse)
						if (enrollResponse.responseCode === ResponseCode.CNP_00 && isThreeDsEnrollResponse(enrollResponse)) {
							const { redirectToACSForm, status } = enrollResponse
							console.log("redirectToACSForm", redirectToACSForm)

							switch (status) {
								// Confirm if its suppose to be allowed to go in a loop
								case threeDSResCodes.PENDING_ENROLLMENT:
									setErrorMessage({ message: "Error during 3DS enrollment. Invalid status", useFriendly: true })
									Logger.error(`1. Error during 3DS enrollment: Pay Ref = ${token}. Data = ${JSON.stringify(enrollResponse)}`)
									break
								case threeDSResCodes.PENDING_ACS:
									Logger.info(JSON.stringify({ redirectToACSForm, status }))
									Logger.info(`opening redirect to acs form for enroll step: Pay Ref = ${token}`)

									// initializeHtml(redirectToACSForm)
									sessionStorage.setItem("ThreeDS", redirectToACSForm)
									navigate("3ds")

									Logger.info(`return after redirect to acs form for enroll step: Pay Ref = ${token}`)
									break
								case threeDSResCodes.SUCCESS:
									Logger.info(`3DS enrollment success: Pay Ref = ${token}.`)
									window.location.replace(
										`https://${window.location.hostname}/cres-redirect?paymentlinktoken=` +
											token +
											`&successUrl=${Buffer.from(transactionData!.successUrl).toString("base64")}&failUrl=${Buffer.from(
												transactionData!.failureUrl,
											).toString("base64")}`,
									)
									return

								case threeDSResCodes.FAILURE:
									Logger.error(`2. 3DS enrollment failure: Pay Ref = ${token}`)
									window.location.replace(
										`https://${window.location.hostname}/cres-failure?failUrl=` +
											Buffer.from(transactionData!.failureUrl).toString("base64") +
											`&paymentlinktoken=${token}`,
									)
									return
								default:
									Logger.error(`3. Error during 3DS enrollment: Pay Ref = ${token}. Data = ${JSON.stringify(enrollResponse)}`)
									// FIXME: Use generic message
									setErrorMessage({ message: "Error during 3DS enrollment. Invalid response", useFriendly: true })
									break
							}
						} else {
							Logger.error(`4.Error during 3DS enrollment: Pay Ref = ${token}. Response = ${JSON.stringify(enrollResponse)}`)
							// FIXME: Use generic message
							setErrorMessage({
								message: enrollResponse.responseMessage || "Error during 3DS enrollment. Invalid response",
								useFriendly: true,
							})
						}
						break
					case threeDSResCodes.PENDING_ACS: {
						Logger.info(`opening redirect to acs form in pre enroll: Pay Ref = ${token}`)

						Logger.info(JSON.stringify({ returnContent, status }))

						// FIXME: Not sure if this is possible. Don't think so though our endpoint types do allow for it
						// initializeHtml(returnContent)
						sessionStorage.setItem("ThreeDS", returnContent)
						navigate("3ds")

						Logger.info(`return redirect to acs form in pre enroll: Pay Ref = ${token}`)
						break
					}
					case threeDSResCodes.SUCCESS:
						Logger.info(`3DS pre enrollment success: Pay Ref = ${token}`)
						window.location.replace(
							`https://${window.location.hostname}/cres-redirect?paymentlinktoken=` +
								token +
								`&successUrl=${Buffer.from(transactionData!.successUrl).toString("base64")}&failUrl=${Buffer.from(
									transactionData!.failureUrl,
								).toString("base64")}`,
						)
						return
					case threeDSResCodes.FAILURE:
						Logger.error(`5. 3DS pre enrollment failure: Pay Ref = ${token}`)
						window.location.replace(
							`https://${window.location.hostname}/cres-failure?failUrl=` +
								Buffer.from(transactionData!.failureUrl).toString("base64") +
								`&paymentlinktoken=${token}`,
						)
						return

					default:
						Logger.error(`6. Error during 3DS pre enrollment: Pay Ref = ${token}`)
						// FIXME: Use generic message
						setErrorMessage({ message: "Error during 3DS pre enrollment. Invalid status", useFriendly: true })
						break
				}
			} else {
				console.log("error during 3ds pre " + JSON.stringify(preEnrollResponse))
				// FIXME: Use generic message
				if (
					preEnrollResponse.responseCode === ResponseCode.CNP_15 &&
					preEnrollResponse.responseMessage?.toLocaleLowerCase().includes("expired")
				) {
					Logger.error(`7. Card Expired. Pay Ref = ${token}. Message = ${preEnrollResponse.responseMessage}`)
					setErrorMessage({ message: "Card Expired", useFriendly: true, canRetry: true })
					setLocalStorageData(StorageTypes.IKPayment, StorageKeys.ErrorSubReason, "Use another payment method or card")
					setLocalStorageData(StorageTypes.IKPayment, StorageKeys.TryAgain, "true")
				} else if (
					preEnrollResponse.responseCode === ResponseCode.CNP_13 &&
					preEnrollResponse.responseMessage?.toLocaleLowerCase().includes("max payment attempts")
				) {
					const attemptsExpiredErrorMessage = generateExpiredErrorMessage("")
					setErrorMessage(attemptsExpiredErrorMessage)
				} else if (
					preEnrollResponse.responseCode === ResponseCode.CNP_01 &&
					preEnrollResponse.responseMessage === "The merchant is disabled."
				) {
					setErrorMessage({
						message: "iKhokha prevented transaction: Prohibited profile status.",
						subMessage: "Please notify the merchant or contact iKhokha",
						useFriendly: true,
					})
				} else if (
					preEnrollResponse.responseCode === ResponseCode.CNP_02 &&
					preEnrollResponse.responseMessage ===
						"The merchant category code is restricted. Please contact your merchant or iK Pay Online at payonline@ikhokha.com"
				) {
					setErrorMessage({
						message: "iKhokha prevented transaction: Merchant Category Code.",
						subMessage: "Please notify the merchant or contact iKhokha",
						useFriendly: true,
					})
				} else {
					setErrorMessage({
						message: preEnrollResponse.responseMessage || "Error during 3DS pre enrollment. Invalid response",
						useFriendly: true,
					})
					Logger.error(`8. Error during 3DS pre enrollment: Pay Ref = ${token}`)
				}
			}

			setLoading(false)
		}

		const checkCanSubmitForm = () => {
			setTimeout(() => {
				const fields = Object.keys(cardDataStore.data.$) as (keyof typeof cardDataStore.data.$)[]

				let formIsValid = true

				const inputsTouched = []

				for (const field of fields) {
					let fieldMessage = ""

					const fieldState = cardDataStore.data.$[field]

					if (fieldState.value.length <= 1) {
						// Set false and break early because we have a falsy value
						fieldMessage = "Short value"
					} else if (fieldState.hasError) {
						fieldMessage = "Has error"
					}

					if (fieldState.dirty) {
						inputsTouched.push(field)
					}

					if (fieldMessage) {
						formIsValid = false
					}
				}

				// Ensure each input has been updated
				// This is to safeguard against a race condition where validation may have not run yet because an input has not been edited yet
				const allTouched = inputsTouched.length === fields.length

				setCanSubmitForm(allTouched && formIsValid)
			}, 500)
		}

		if (error) {
			console.error(error.message)
		}

		return isLoading ? (
			<Box
				sx={{
					width: [theme.breakpoints.up("xs")] || [theme.breakpoints.up("sm")] ? "inherit" : "100%",
					height: [theme.breakpoints.up("xs")] || [theme.breakpoints.up("sm")] ? "inherit" : "100vh",
					margin: [theme.breakpoints.up("xs")] || [theme.breakpoints.up("sm")] ? "0 !important" : "40px",
					padding: [theme.breakpoints.up("xs")] || [theme.breakpoints.up("sm")] ? "0 !important" : "40px",
					overflow: "hidden",
					// [theme.breakpoints.up('xs')]: {
					// 	width: 'inherit',
					// 	height: 'inherit',
					// 	margin: '0',
					// 	padding: '0'
					// },
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<CircularProgress
					sx={{
						color: "#898b8c",
					}}
					size={24}
				/>
			</Box>
		) : isLoading ? (
			<Loader />
		) : error ? (
			<LoaderThatRedirects success={false} message={""} />
		) : transactionData ? (
			<Container
				sx={{
					width: [theme.breakpoints.up("xs")] || [theme.breakpoints.up("sm")] ? "inherit" : "100%",
					height: [theme.breakpoints.up("xs")] || [theme.breakpoints.up("sm")] ? "inherit" : "100vh",
					margin: [theme.breakpoints.up("xs")] || [theme.breakpoints.up("sm")] ? "0 !important" : "40px",
					padding: [theme.breakpoints.up("xs")] || [theme.breakpoints.up("sm")] ? "0 !important" : "40px",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<Stack
					sx={{
						width: "340px",
						borderRadius: "16px",
						backgroundColor: theme.palette.background.paper,
						padding: [theme.breakpoints.up("xs")] || [theme.breakpoints.up("sm")] ? "0 !important" : "16px",
					}}
				>
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							marginBottom: "16px",
						}}
					>
						<img style={{ marginRight: "8px", width: "67.69px", height: "24px" }} src={VisaLogo} alt="visa logo" />
						<img style={{ width: "67.69px", height: "24px" }} src={MasterCardLogo} alt="mc logo" />
					</Box>
					<Box>
						<form onSubmit={handleSubmit} onChange={checkCanSubmitForm}>
							<Box>
								<TextField
									sx={{
										marginBottom: "1rem",
									}}
									inputProps={{ minLength: 3, maxLength: 64 }}
									fullWidth
									label="Name on Card"
									name="name"
									type="text"
									value={cardDataStore.data.$.cardholderName.value}
									variant="filled"
									size="medium"
									onChange={(e) => {
										const input = e.target.value
										// TODO: FIXME: Confirm sanitization
										const lettersAndSpacesOnly = input.replace(/[^A-Z ]/gi, "")
										cardDataStore.data.$.cardholderName.onChange(lettersAndSpacesOnly)
									}}
									error={!!cardDataStore.data.$.cardholderName.hasError}
									required
								/>
							</Box>
							<Box>
								<TextField
									sx={{
										marginBottom: "1rem",
									}}
									inputProps={{ minLength: 14, maxLength: 23 }}
									fullWidth
									label="Card Number"
									name="card"
									type="tel"
									value={cardDataStore.data.$.pan.value.match(/.{1,4}/g)?.join(" ") || ""}
									variant="filled"
									size="medium"
									onChange={(e) => {
										const input = e.target.value
										const numbersOnly = input.replace(/[^0-9]/gi, "")
										cardDataStore.data.$.pan.onChange(numbersOnly)
									}}
									error={!!cardDataStore.data.$.pan.hasError}
									required
								/>
							</Box>
							<Stack direction={"row"} justifyContent={"space-between"}>
								<Box
									sx={{
										width: "45%",
									}}
								>
									<TextField
										sx={{
											marginBottom: "1rem",
										}}
										inputProps={{ minLength: 2, maxLength: 2 }}
										fullWidth
													label="Expiry Month"
										name="month"
										type="tel"
										variant="filled"
										size="medium"
										onChange={(e) => {
											const input = e.target.value
											const numbersOnly = input.replace(/[^0-9]/gi, "")
											cardDataStore.data.$.expiryMonth.onChange(numbersOnly)
											if (Number(numbersOnly) <= 12 && numbersOnly.length >= 2) {
												if (yearField.current !== null) {
													yearField.current.focus()
												}
											}
										}}
										value={cardDataStore.data.$.expiryMonth.value}
										error={!!cardDataStore.data.$.expiryMonth.hasError}
										required
									/>
								</Box>
								<Box
									sx={{
										width: "45%",
									}}
								>
									<TextField
										sx={{
											marginBottom: "1rem",
										}}
										inputProps={{ minLength: 2, maxLength: 2 }}
										fullWidth
													label="Expiry Year"
										name="year"
										type="tel"
										variant="filled"
										size="medium"
										onChange={(e) => {
											const input = e.target.value
											const numbersOnly = input.replace(/[^0-9]/gi, "")
											cardDataStore.data.$.expiryYear.onChange(numbersOnly)
										}}
										value={cardDataStore.data.$.expiryYear.value}
										error={!!cardDataStore.data.$.expiryYear.hasError}
										required
													inputRef={yearField}
									/>
								</Box>
							</Stack>
							<Box>
								<TextField
									sx={{
										marginBottom: "1rem",
									}}
									inputProps={{ minLength: 3, maxLength: 4 }}
									fullWidth
									label="CVV"
									name="cvv"
									type="tel"
									value={cardDataStore.data.$.cvv.value}
									variant="filled"
									size="medium"
									onChange={(e) => {
										const input = e.currentTarget.value
										const numbersOnly = input.replace(/[^0-9]/gi, "")
										if (numbersOnly.length <= 4) {
											cardDataStore.data.$.cvv.onChange(numbersOnly)
										}
									}}
									error={!!cardDataStore.data.$.cvv.hasError}
									helperText={"See the 3 digits on the back of your card."}
									required
								/>
							</Box>

							<Box sx={{ width: "100%" }}>
								<Button
									sx={{ width: "100%" }}
									variant="contained"
									color="primary"
									size="large"
									disabled={!canSubmitForm}
									type="submit"
								>
									{isProgress ? (
										<CircularProgress
											sx={{
												color: "#898b8c",
											}}
											size={24}
										/>
									) : (
										`Pay R${formatCurrency(transactionData.amount / 100)}`
									)}
								</Button>
								{/* {transactionData.displayConfig?.backToOrder && (
									<Box className={cardPaymentModule.ButtonBlock_buttonBlock} onClick={() => cancelTransaction(token)}>
										<ReturnToOrder
											platformName={transactionData.cancelUrl}
											fromPage={EcommMetrics.RETURN_TO_ORDER}
											returnUrl={transactionData.cancelUrl}
											eventTracker={eventsTracker}
										/>
									</Box>
								)} */}
							</Box>
						</form>
					</Box>
				</Stack>
			</Container>
		) : (
			<LoaderThatRedirects success={false} message={"Unable to load paylink"} />
		)
	},
)

export default CardPaymentPage
