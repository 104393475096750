import threeDSLogo from "../../assets/images/3ds_logo.svg"
import pciLogo from "../../assets/images/pci-logo-v2.svg"
import footerModule from "./footer.module.css"
import React from "react"

export default function Footer(): JSX.Element {
	return (
		<footer>
			<div className={footerModule.ikpaymentContainer__footer}>
				<div className={footerModule.securityLogoPositioner}>
					<img src={threeDSLogo} alt="3DS logo" />
					<img src={pciLogo} alt="PCI logo" />
				</div>
			</div>
		</footer>
	)
}
