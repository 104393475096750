import React from "react"
import "./card.css"
import { Box, Card as MuiCard, Typography } from "@mui/material"
//TODO: REFACTOR THE TYPES/ INTERFACE
export default interface ICard {
	src: string
	alt: string
	key?: any
	href?: string
	message?: string
	altMessage?: string
	heading: string
}

export const Card: React.FunctionComponent<ICard> = ({ ...props }) => {
	return (
		<>
			<Box>
				<MuiCard
					sx={{
						display: "flex",
						alignItems: "center",
						flexDirection: "column",
						width: "80vw",
						height: "250px",
						fontSize: "1.6rem",
						padding: "2rem",
						borderRadius: "12px",
						maxWidth: "340px",
						margin: "2.5rem 0 2.5rem 0",
					}}
				>
					<Box className="icons">
						<img src={props.src} alt={props.alt} />
					</Box>
					<Box
						sx={{
							padding: "20px",
						}}
					>
						<Typography
							sx={{
								fontWeight: 600,
								margin: "8px",
								fontSize: "14px",
							}}
						>
							{props.heading}
						</Typography>
						{props.href && (
							<Typography
								sx={{
									fontSize: "14px",
								}}
							>
								<a href={props.href}>{props.message}</a>
							</Typography>
						)}

						<Typography>{props.altMessage && <p>{props.altMessage}</p>}</Typography>
					</Box>
				</MuiCard>
			</Box>
		</>
	)
}
