import FailIcon from "../../../assets/images/error.svg"
import { PrimaryButton } from "../../../components/buttons/PrimaryButton"
import { Card } from "../../../components/cards/Card"
import { Container } from "../../../components/container"
import Footer from "../../../components/footer/Footer"
import Layout from "../../../components/layout/Layout"
import cards from "../../../config/cards"
import React, { useEffect, useState } from "react"
import { PluginError } from "../../../utils"
import { Buffer } from "buffer"
import { IButtonClickEventAttributes, mxPanelEventName } from "src/utils/mxPanel"
import { EcommMetrics } from "src/utils/metrics"
import EventsTracker from "src/helpers/eventsTracker"
import { MxPanelEvents } from "src/helpers/mxPanel"

const PluginFailurePage: React.FunctionComponent = () => {
	const referrerUrl = document.referrer
	const [pluginError, setPluginError] = useState({ message: "", subMessage: "" })
	const error = new URLSearchParams(document.location.search).get("error")

	useEffect(() => {
		if (error) {
			switch (Buffer.from(error, "base64").toString("ascii")) {
				case PluginError.DISABLED:
					setPluginError({
						message: "iKhokha prevented transaction: Prohibited profile status.",
						subMessage: "Please notify the merchant or contact iKhokha",
					})
					break
				case PluginError.CATEGORY:
					setPluginError({
						message: "iKhokha prevented transaction: Merchant Category Code.",
						subMessage: "Please notify the merchant or contact iKhokha",
					})
					break
			}
		}
	}, [error])

	function backtoMerchantStore(url: string) {
		let eventsTracker = new EventsTracker(new MxPanelEvents())
		const event: IButtonClickEventAttributes = {
			page_title: EcommMetrics.PLUGIN_FAILURE,
			page_url: `${window.location.href}`,
			button_text: EcommMetrics.BACK_TO_MERCHANT_STORE,
			date_time: new Date().toDateString(),
			event_type: mxPanelEventName.BUTTON_CLICK,
			button_url: url,
		}
		eventsTracker.logCustomEvent(mxPanelEventName.BUTTON_CLICK, event)

		if (url) {
			window.location.href = url
		}
	}

	return (
		<div>
			<Layout>
				<Container
					src={FailIcon}
					alt="Transaction Failed Icon"
					title="Transaction Failed"
					errorMessage={pluginError.message || ""}
					message={
						pluginError.subMessage ||
						"We were unable to process your payment. Please try again or contact our support team for further help."
					}
				>
					{pluginError.message && referrerUrl && (
						<PrimaryButton style={{ fontSize: "18px" }} onClick={() => backtoMerchantStore(referrerUrl)}>
							{"Back to merchant store"}
						</PrimaryButton>
					)}
				</Container>
				<Footer />
				<div className="support_header">
					<h2>Got questions or need our help?</h2>
				</div>
				<div className="card_container">
					{cards.map((card, index) => {
						return (
							<Card
								key={index}
								src={card.src}
								alt={card.alt}
								heading={card.heading}
								href={card.href}
								message={card.message}
								altMessage={card.altMessage}
							/>
						)
					})}
				</div>
			</Layout>
		</div>
	)
}

export default PluginFailurePage
