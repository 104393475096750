import Logo from "../../../assets/images/ik-logo-black-v2.svg"
import React from "react"
import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import Box from "@mui/material/Box"
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"

export default function Navbar() {
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

	return (
		<AppBar
			position="static"
			color="default"
			elevation={0}
			sx={{
				backgroundColor: "#ffffff",
				borderBottom: "1.2px solid #E8E8E7",
			}}
		>
			<Toolbar
				sx={{
					minHeight: isMobile ? "56px" : "64px",
					justifyContent: "center",
					alignItems: "center",
					width: "100%",
				}}
			>
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						width: "100%",
					}}
				>
					<img
						src={Logo}
						alt="iKhokha logo"
						style={{ height: "26px", width: "128px" }} // Adjust logo size for mobile
					/>
				</Box>
			</Toolbar>
		</AppBar>
	)
}
